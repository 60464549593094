import { AxiosRequestConfig } from 'axios'

import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import { InterviewLaborerStatus } from 'common/types/commonTypes'
import { LaborerInterviewFromBE, PayfortBill } from 'common/types/transformedRespTypes'

import { callAPI, interviewAPIWithCredentials } from './axios'

export type GetInterviewsListParams = {
  perPage?: number
  page?: number
}

export type GetInterviewsListResponse = {
  data: Array<LaborerInterviewFromBE>
  meta: {
    current_page: number
    last_page: number
  }
}

const getInterviewsList = (
  { perPage = LIST_ITEMS_PER_PAGE, page = 0 }: GetInterviewsListParams,
  config?: AxiosRequestConfig,
) =>
  interviewAPIWithCredentials.get<GetInterviewsListResponse>('/api/individuals/interviews', {
    params: {
      per_page: perPage,
      page: page + 1,
    },
    ...config,
  })

export type GetInterviewParams = {
  id: string
}

export type GetInterviewResponse = {
  data: LaborerInterviewFromBE
}

const getInterview = ({ id }: GetInterviewParams, config?: AxiosRequestConfig) =>
  interviewAPIWithCredentials.get<GetInterviewResponse>(`/api/individuals/interviews/${id}`, config)

export type GetPdfReportParams = {
  id: string
  lang: string
}

export type GetPdfReportResponse = Blob

const getPdfReport = ({ id, lang }: GetPdfReportParams, config?: AxiosRequestConfig) =>
  interviewAPIWithCredentials.get<GetPdfReportResponse>(`/api/individuals/interviews/${id}/pdf?lang=${lang}`, {
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
    ...config,
  })

export type GetLaborerProsParams = {
  passportNumber: string
  nicCode: number
}

export type GetLaborerProsResponse = {
  status: InterviewLaborerStatus
  pros: Array<string> | null
}

const getLaborerPros = ({ passportNumber, nicCode }: GetLaborerProsParams, config?: AxiosRequestConfig) =>
  interviewAPIWithCredentials.get<GetLaborerProsResponse>(
    `/api/individuals/interviews/laborer-pros/${passportNumber}/${nicCode}`,
    config,
  )

export type UploadPassportCopyParams = {
  file: File
}

export type UploadPassportCopyResponse = {
  key: string
}

const uploadPassportCopy = ({ file }: UploadPassportCopyParams, config: AxiosRequestConfig) => {
  const data = new FormData()
  data.append('file', file)
  return interviewAPIWithCredentials.post<UploadPassportCopyResponse>('/api/media/individuals/upload', data, config)
}

export type CreateInterviewParams = {
  laborer: {
    name: string
    mobile: string
    ageGroup: string
    passportNumber: string
    passportExpiryDate: string
    passportUpload: string
    nationalityCode: number
    occupationCode: string
  }
  pros: {
    id: number
    name: string
    unifiedId: string
    contactNumber: string
    email: string
  }
}

export type CreateInterviewResponse = {
  data: {
    bill: {
      amount: number
      breakdown: {
        service_price: number
        total: number
        vat: number
      }
      created_at: string
      id: number
      status: string
      uuid: string
    }
    created_at: string
    display_id: number
    status: string
  }
}

const createInterview = ({ laborer, pros }: CreateInterviewParams, config: AxiosRequestConfig) =>
  interviewAPIWithCredentials.post<CreateInterviewResponse>(
    '/api/individuals/interviews',
    {
      laborer: {
        name: laborer.name,
        mobile: laborer.mobile,
        age_group: laborer.ageGroup,
        passport: laborer.passportNumber,
        passport_expiry: laborer.passportExpiryDate,
        passport_upload: laborer.passportUpload,
        nationality_code: laborer.nationalityCode.toString(),
        occupation_code: laborer.occupationCode,
      },
      pros: [
        {
          pro_id: pros.id.toString(),
          name: pros.name,
          unified_id: pros.unifiedId,
          contact_number: pros.contactNumber,
          email: pros.email,
        },
      ],
    },
    config,
  )

export type PayForInterviewParams = {
  uuid: string
}

export type PayForInterviewResponse = {
  data: {
    tokenization_data: PayfortBill
  }
}

export type PayForInterviewFailResponse = {
  error?: string
  status?: boolean
  data: PayfortBill
}

const payForInterview = ({ uuid }: PayForInterviewParams, config?: AxiosRequestConfig) =>
  interviewAPIWithCredentials.get<PayForInterviewResponse>(`/api/individuals/interviews/bill/${uuid}/payment`, config)

export type GetInterviewBillParams = {
  uuid: string
}

export type GetInterviewBillResponse = {
  data: any
}

const getInterviewBill = ({ uuid }: GetInterviewBillParams, config?: AxiosRequestConfig) =>
  interviewAPIWithCredentials.get<GetInterviewBillResponse>(`/api/individuals/interviews/bill/${uuid}/receipt`, config)

export default {
  getInterviewsList: callAPI<typeof getInterviewsList, GetInterviewsListResponse>(getInterviewsList),
  getInterview: callAPI<typeof getInterview, GetInterviewResponse>(getInterview),
  getPdfReport: callAPI<typeof getPdfReport, GetPdfReportResponse>(getPdfReport),
  getLaborerPros: callAPI<typeof getLaborerPros, GetLaborerProsResponse>(getLaborerPros),
  uploadPassportCopy: callAPI<typeof uploadPassportCopy, UploadPassportCopyResponse>(uploadPassportCopy),
  createInterview: callAPI<typeof createInterview, CreateInterviewResponse>(createInterview),
  payForInterview: callAPI<typeof payForInterview, PayForInterviewResponse>(payForInterview),
  getInterviewBill: callAPI<typeof getInterviewBill, GetInterviewBillResponse>(getInterviewBill),
}
