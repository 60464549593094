export enum Routes {
  // unified journey related routes
  SERVICES = '/services',
  RECRUITMENT_OFFICES = '/recruitment-offices',
  SURVEY = '/survey',
  LABOR_ONE = '/labor1',
  LABOR_TWO = '/labor2',
  MAAROFA_SERVICES = '/maarofa-services',
  HOME = '/home',
  MARKET = '/marketplace',
  ATTACHMENTS = '/attachments',
  CONFIRMATION = '/confirmation',
  FAQ = '/faq',
  FAQ_DETAILS = '/faq-details',
  SUPPORT_PAGE = '/support-form',
  SUPPORT = '/support',
  CONTACT_US = '/contact-us',
  AUTH_CONTRACT_INFO = '/info/authentication-contract/:contractId',
  UNSUBSCRIBE_LABORER = '/unsubscribe',
  SERVICE_DETAILS = '/services/details',

  // terms related routes
  TERMS = '/terms',
  MAAROFA_TERMS = '/terms/maarofa',
  PRIVACY_POLICY = '/terms/privacy-policy',
  INSTALLMENT_SERVICE_TERMS = '/terms/installment-service',

  // auth related routes
  AUTH = '/auth',
  LOGIN = '/auth/login',
  LOGIN_NAFATH = '/auth/login-nafath',
  LOGIN_LABORER = '/auth/login-laborer',
  RESET_PASSWORD = '/auth/reset-password',
  SIGNUP = '/auth/signup',

  // app related routes
  APP = '/app',
  DASHBOARD = '/app/dashboard',
  LABORER_DASHBOARD = '/app/laborer-dashboard',
  SERVICE_CATALOGUE = '/app/service-catalogue',
  SERVICE_CATALOGUE_DETAILS = '/app/service-catalogue/details',

  // vip user routes
  VIP = '/vip',
  VIP_TERMS = '/vip/terms',
  VIP_DASHBOARD = '/vip/dashboard',
  VIP_PROFILE = '/vip/profile',
  VIP_PROFILE_DETAILS = '/vip/profile/details',
  VIP_PROFILE_REFUND = '/vip/profile/refund',
  VIP_PROFILE_REFUND_SADAD = '/vip/profile/refund/sadad/:billId',
  VIP_PROFILE_REFUND_PAY_FORT = '/vip/profile/refund/payfort/:billId',
  VIP_PROFILE_SPONSORED_LIST = '/vip/profile/sponsored-list',
  VIP_RUNAWAYS = '/vip/profile/runaways',
  VIP_PROFILE_CHANGE_PASSWORD = '/vip/profile/change-password',
  VIP_RECRUITMENT = '/vip/recruitment',
  VIP_RECRUITMENT_SELECT_TYPE = '/vip/recruitment/select-type',
  VIP_RECRUITMENT_LABORER_INFO = '/vip/recruitment/laborer-info',
  VIP_RECRUITMENT_APPROVAL = '/vip/recruitment/approval',
  VIP_RECRUITMENT_MARKETPLACE = '/vip/recruitment/marketplace',
  VIP_RECRUITMENT_NATIONALITY_TERMS = '/vip/recruitment/nationality-terms',
  VIP_RECRUITMENT_CONFIRMATION = '/vip/recruitment/confirmation',

  VIP_LABORS = '/vip/labors',
  VIP_FINANCIAL_EXEMPTION = '/vip/laborer/financial-exemption/:laborerId',
  VIP_LABOR_DETAILS = '/vip/laborer/:laborerId',
  VIP_LABOR_TRANSACTION_DETAILS = '/vip/laborer/:laborerId/transactions/:transactionId',

  VIP_SERVICE_TRANSFER = '/vip/service-transfer',

  VIP_SERVICE_TRANSFER_CREATE_ESTABLISHMENT = '/vip/service-transfer/establishment-to-individual/create',
  VIP_SERVICE_TRANSFER_VALIDATE_ESTABLISHMENT = '/vip/service-transfer/establishment-to-individual/validate',
  VIP_SERVICE_TRANSFER_DISCLAIMER_ESTABLISHMENT = '/vip/service-transfer/establishment-to-individual/disclaimer',
  VIP_SERVICE_TRANSFER_ATTACHMENTS_ESTABLISHMENT = '/vip/service-transfer/establishment-to-individual/attachments',
  VIP_SERVICE_TRANSFER_FINALIZE_ESTABLISHMENT = '/vip/service-transfer/establishment-to-individual/finalize',

  VIP_SERVICE_TRANSFER_E_TO_I_REQUEST = '/vip/service-transfer/establishment-to-individual/request/:requestId',
  VIP_SERVICE_TRANSFER_E_TO_I_REQUEST_TRANSFER = '/vip/service-transfer/establishment-to-individual/request/:requestId/transfer',
  VIP_SERVICE_TRANSFER_E_TO_I_REQUEST_CANCEL = '/vip/service-transfer/establishment-to-individual/request/:requestId/cancel',
  VIP_SERVICE_TRANSFER_E_TO_I_REQUEST_REPLACE = '/vip/service-transfer/establishment-to-individual/request/:requestId/replace/:laborerId',
  VIP_SERVICE_TRANSFER_E_TO_I_ACCEPT_CONTRACT = '/vip/service-transfer/establishment-to-individual/request/:requestId/accept/contract',
  VIP_SERVICE_TRANSFER_E_TO_I_ACCEPT_ATTACHMENTS = '/vip/service-transfer/establishment-to-individual/request/:requestId/accept/attachments',
  VIP_SERVICE_TRANSFER_E_TO_I_ACCEPT_PAYMENT = '/vip/service-transfer/establishment-to-individual/request/:requestId/accept/payment',

  VIP_SERVICE_TRANSFER_I_TO_I_REQUEST = '/vip/service-transfer/individual-to-individual/request/:requestId',
  VIP_SERVICE_TRANSFER_I_TO_I_REQUEST_APPROVAL = '/vip/service-transfer/individual-to-individual/request/approval',
  VIP_SERVICE_TRANSFER_I_TO_I_REQUEST_LABORERS = '/vip/service-transfer/individual-to-individual/request/laborers',
  VIP_SERVICE_TRANSFER_I_TO_I_REQUEST_FORM = '/vip/service-transfer/individual-to-individual/request/form',
  VIP_SERVICE_TRANSFER_I_TO_I_APPROVAL = '/vip/service-transfer/individual-to-individual/request/:requestId/approval',
  VIP_SERVICE_TRANSFER_I_TO_I_TRANSFER_CONTRACT = '/vip/service-transfer/individual-to-individual/request/:requestId/transfer-contract',
  VIP_SERVICE_TRANSFER_I_TO_I_EMPLOYMENT_CONTRACT = '/vip/service-transfer/individual-to-individual/request/:requestId/employment-contract',
  VIP_SERVICE_TRANSFER_I_TO_I_ATTACHMENTS = '/vip/service-transfer/individual-to-individual/request/:requestId/attachments',
  VIP_SERVICE_TRANSFER_I_TO_I_PAYMENT = '/vip/service-transfer/individual-to-individual/request/:requestId/payment',

  VIP_FARMER_TRANSFER_REQUEST_FORM = '/vip/service-transfer/farmer-request/form',
  VIP_FARMER_TRANSFER_REQUEST_SUMMARY = '/vip/service-transfer/farmer-request/request-summary',
  VIP_FARMER_TRANSFER_REQUEST_DISCLAIMER = '/vip/service-transfer/farmer-request/disclaimer',
  VIP_FARMER_TRANSFER_REQUEST_PAYMENT = '/vip/service-transfer/farmer-request/:requestId/payment',

  VIP_WAGE_PROTECTION_LABOR_DETAILS = '/vip/laborer/:laborerId',

  VIP_CONTRACTS = '/vip/contracts',
  VIP_CONTRACT_REQUEST = '/vip/contracts/:requestId',
  VIP_CONTRACT_LABORER_DETAILS = '/vip/contracts/:requestId/offers/:offerId/cv/:cvId',
  VIP_CONTRACT_NATIONALITY_TERMS = '/vip/contracts/:requestId/offers/:offerId/nationality-terms/:nationalityId',
  VIP_CONTRACT_ADDITIONAL_SERVICES = '/vip/contracts/:requestId/offers/:offerId/cv/:cvId/additional-services',
  VIP_ADDITIONAL_SERVICES = '/vip/contracts/:requestId/offers/:offerId/additional-services',
  VIP_SELECT_INSURANCE = '/vip/contracts/:requestId/offers/:offerId/choose-insurance',
  VIP_CONTRACT_PAYMENT_PAGE = '/vip/contracts/:requestId/offers/:offerId/payment',
  VIP_CONTRACT_DETAILS = '/vip/contracts/:requestId/offers/:offerId/contract-details',
  VIP_CONTRACT_CANCELLATION = '/vip/contracts/:requestId/offers/:offerId/cancellation',
  VIP_START_REFUND = '/vip/contracts/:requestId/offers/:offerId/refund',
  VIP_CONTRACT_COMPLAINT = '/vip/contracts/:requestId/offers/:offerId/complaint',

  VIP_CONTRACT_AUTH_INSTRUCTION = '/vip/contracts/request-authentication/instruction',
  VIP_CONTRACT_AUTH_APPROVAL = '/vip/contracts/request-authentication/approval',
  VIP_CONTRACT_AUTH_LABORERS_LIST = '/vip/contracts/request-authentication/laborers-list',
  VIP_CONTRACT_AUTH_REQUEST_DETAILS = '/vip/contracts/request-authentication/contract-details',
  VIP_CONTRACT_AUTH_LABORER_DETAILS = '/vip/contracts/request-authentication/laborer-details',
  VIP_CONTRACT_AUTH_CONTRACT_PREVIEW = '/vip/contracts/request-authentication/contract-preview',

  VIP_CONTRACT_AUTH_REVIEW_CONTRACT = '/vip/contracts/authentication/:requestId/review-authentication-contract',
  VIP_CONTRACT_AUTH_CANCELLATION = '/vip/contracts/authentication/:requestId/cancel-authentication-contract',
  VIP_CONTRACT_AUTH_EDIT_CONTRACT = '/vip/contracts/authentication/:requestId/edit-authentication-contract',
  VIP_CONTRACT_AUTH_REVIEW_APPENDIX = '/vip/contracts/authentication/:requestId/review-authentication-appendix',
  VIP_CONTRACT_AUTH_EDIT_MOBILE = '/vip/contracts/authentication/:requestId/edit-laborer-mobile',

  VIP_VISAS = '/vip/evisa',
  VIP_VISA_DETAILS = '/vip/evisa/:requestId/details',
  VIP_FARMER_VISA_DETAILS = '/vip/evisa/farmer/:requestId/details',
  VIP_VISA_PAYMENT = '/vip/evisa/:requestId/payment',
  VIP_FARMER_VISA_PAYMENT = '/vip/evisa/farmer/:requestId/payment',
  VIP_FARMER_VISA_REFUND_SADAD = '/vip/evisa/farmer/:requestId/refund/sadad/:billId',
  VIP_FARMER_VISA_REFUND_PAYFORT = '/vip/evisa/farmer/:requestId/refund/payfort/:billId',
  VIP_VISA_REFUND_SADAD = '/vip/evisa/:requestId/refund/sadad/:billId',
  VIP_VISA_REFUND_PAYFORT = '/vip/evisa/:requestId/refund/payfort/:billId',

  // VIP Notices
  VIP_NOTICES = '/vip/notices',
  VIP_NOTICES_CREATE_DISCLAIMER = '/vip/notices/create',
  VIP_NOTICES_CREATE_DETAILS = '/vip/notices/create/details',
  VIP_NOTICES_PAYMENT = '/vip/notices/:requestId/payment',

  // VIP Complaints
  VIP_COMPLAINTS = '/vip/complaints',
  VIP_COMPLAINT_DETAILS = '/vip/complaints/:ticketKey',

  // VIP Support Tickets
  VIP_SUPPORT_TICKETS = '/vip/support-tickets',
  VIP_SUPPORT_TICKETS_CREATE = '/vip/support-tickets/create',
  VIP_SUPPORT_TICKET_DETAILS = '/vip/support-tickets/details/:ticketId',

  // Visas request
  VIP_REGULAR_VISA_CONFIRMATION = '/vip/evisa/regular/confirmation',
  VIP_REGULAR_VISA_ATTACHMENTS = '/vip/evisa/regular/attachments',
  VIP_DISABILITY_VISA_CONFIRMATION = '/vip/evisa/disability/confirmation',
  VIP_REPLACEMENT_VISA_CONFIRMATION = '/vip/evisa/replacement/confirmation',
  VIP_FARMER_VISA_DESCRIPTION = '/vip/evisa/farmer/description',
  VIP_FARMER_VISA_DISCLAIMER = '/vip/evisa/farmer/disclaimer',
  VIP_FARMER_VISA_CONFIRMATION = '/vip/evisa/farmer/confirmation',
  VIP_FARMER_VISA_ENDORSEMENT_SELECT = '/vip/evisa/farmer/endorsement-select',
  VIP_FARMER_VISA_ESTABLISHMENT_REQUEST = '/vip/evisa/farmer/establishment/request',
  VIP_FARMER_VISA_ESTABLISHMENT_CONFIRMATION = '/vip/evisa/farmer/establishment/confirmation',

  // recruitment related routes
  RECRUITMENT_JOURNEY = '/app/recruitment',
  RECRUITMENT_GENDER = '/app/recruitment/gender',
  RECRUITMENT_OCCUPATION = '/app/recruitment/occupation',
  RECRUITMENT_NATIONALITY = '/app/recruitment/nationality',
  RECRUITMENT_LABORER_INFO = '/app/recruitment/laborer-info',
  RECRUITMENT_PREFERENCES = '/app/recruitment/preferences',
  RECRUITMENT_PRESELECTED = '/app/recruitment/preselected',
  RECRUITMENT_SELECT_TYPE = '/app/recruitment/select-type',
  RECRUITMENT_MARKET = '/app/recruitment/market',
  RECRUITMENT_PERSONAL_DATA = '/app/recruitment/personal-data',
  RECRUITMENT_ELIGIBILITY = '/app/recruitment/eligibility',
  RECRUITMENT_CONFIRMATION = '/app/recruitment/confirmation',
  RECRUITMENT_SUCCESS_PAGE = '/app/recruitment/success-page',
  RECRUITMENT_NATIONALITY_TERMS = '/app/recruitment/nationality-terms',

  // hiring laborer inside KSA related routes
  HIRING_LABORER = '/app/hiring-laborer',
  LISTING_LABORER_PREFERENCES = '/app/listing-laborer',
  LISTING_LABORER_MARKET = '/app/listing-laborer/market',
  LISTING_LABORER_DETAILS = '/app/listing-laborer/:laborerId',
  LISTING_LABORER_NATIONALITY_TERMS = '/app/listing-laborer/:laborerId/nationality-terms',
  LISTING_LABORER_TRANSFER_DETAILS = '/app/listing-laborer/:laborerId/transfer-details',

  // notices
  NOTICES = '/app/notices',
  NOTICES_CREATE_DISCLAIMER = '/app/notices/create',
  NOTICES_CREATE_DETAILS = '/app/notices/create/details',
  NOTICES_PAYMENT = '/app/notices/:requestId/payment',

  // app contract related routes
  CONTRACTS = '/app/contracts',
  CONTRACT_REQUEST = '/app/contracts/:requestId',
  CONTRACT_CANCELLATION = '/app/contracts/:requestId/offers/:offerId/cancellation',
  START_REFUND = '/app/contracts/:requestId/offers/:offerId/refund',
  CONTRACT_COMPLAINT = '/app/contracts/:requestId/offers/:offerId/complaint',
  CONTRACT_EXTENSION = '/app/contracts/:requestId/offers/:offerId/extension',
  CANCELLATION_REQUEST = '/app/contracts/:requestId/offers/:offerId/cancellation-request',
  CV_SELECT = '/app/contracts/:requestId/offers/:offerId/cv-select',
  CONTRACT_DETAILS = '/app/contracts/:requestId/offers/:offerId/contract-details',
  CONTRACT_NATIONALITY_TERMS = '/app/contracts/:requestId/offers/:offerId/nationality-terms/:nationalityId',
  CONTRACT_PAYMENT = '/app/contracts/:requestId/offers/:offerId/payment',
  CONTRACT_INSURANCE_SELECTION = '/app/contracts/:requestId/offers/:offerId/accept-offer',
  CONTRACT_CHOOSE_INSURANCE = '/app/contracts/:requestId/offers/:offerId/choose-insurance',
  CONTRACT_TAMEENI_INSURANCE = '/app/contracts/:requestId/offers/:offerId/tameeni-disclaimer',
  CONTRACT_SUCCESS_PAGE = '/app/contracts/:requestId/offers/:offerId/success',

  // domestic transfer related routes
  SERVICE_TRANSFER_SPONSOR = '/app/service-transfer/sponsor',
  SERVICE_TRANSFER = '/app/service-transfer',
  SERVICE_TRANSFER_REQUEST_TYPE = '/app/service-transfer/request-type',
  SERVICE_TRANSFER_LIST_LABORERS = '/app/service-transfer/list-laborer/laborers',
  SERVICE_TRANSFER_LIST_LABORER_FORM = '/app/service-transfer/list-laborer/form',

  LABORER_AUTH_REQUEST = '/app/laborer-authentication-request',
  LABORER_AUTH_EMPLOYMENT_CONTRACT = '/app/laborer-authentication-request/:requestId/employment-contract',
  LABORER_AUTH_REQUEST_DETAILS = '/app/laborer-authentication-request/:requestId',
  LABORER_AUTH_APPENDIX_DETAILS = '/app/laborer-authentication-request/:requestId/appendix/:appendixId',
  LABORER_SERVICE_TRANSFER = '/app/laborer-service-transfer',
  LABORER_RELATIVE_INFORMATION = '/app/laborer-service-transfer/:requestId/relation-information',
  LABORER_TRANSFER_CONTRACT = '/app/laborer-service-transfer/:requestId/transfer-contract',
  LABORER_EMPLOYMENT_CONTRACT = '/app/laborer-service-transfer/:requestId/employment-contract',
  LABORER_SERVICE_TRANSFER_DETAILS = '/app/laborer-service-transfer/:requestId',

  LABORER_SUPPORT = '/app/laborer-support',
  LABORER_SUPPORT_DETAILS = '/app/laborer-support/:ticketKey',
  LABORER_SUPPORT_REQUEST = '/app/laborer-support-request',
  LABORER_SUPPORT_REQUEST_DETAILS = '/app/laborer-support-request/details',
  LABORER_SUPPORT_REQUEST_CONFIRM = '/app/laborer-support-request/confirm',

  LABORER_LISTING_REQUESTS = '/app/laborer-listing-requests',
  LABORER_LISTING_REQUEST_DETAILS = '/app/laborer-listing-requests/:requestId',

  SERVICE_TRANSFER_CREATE_ESTABLISHMENT = '/app/service-transfer/establishment-to-individual/create',
  SERVICE_TRANSFER_VALIDATE_ESTABLISHMENT = '/app/service-transfer/establishment-to-individual/validate',
  SERVICE_TRANSFER_DISCLAIMER_ESTABLISHMENT = '/app/service-transfer/establishment-to-individual/disclaimer',
  SERVICE_TRANSFER_ATTACHMENTS_ESTABLISHMENT = '/app/service-transfer/establishment-to-individual/attachments',
  SERVICE_TRANSFER_FINALIZE_ESTABLISHMENT = '/app/service-transfer/establishment-to-individual/finalize',

  SERVICE_TRANSFER_E_TO_I_REQUEST = '/app/service-transfer/establishment-to-individual/request/:requestId',
  SERVICE_TRANSFER_E_TO_I_REQUEST_TRANSFER = '/app/service-transfer/establishment-to-individual/request/:requestId/transfer',
  SERVICE_TRANSFER_E_TO_I_REQUEST_CANCEL = '/app/service-transfer/establishment-to-individual/request/:requestId/cancel',
  SERVICE_TRANSFER_E_TO_I_REQUEST_REPLACE = '/app/service-transfer/establishment-to-individual/request/:requestId/replace/:laborerId',
  SERVICE_TRANSFER_E_TO_I_ACCEPT_CONTRACT = '/app/service-transfer/establishment-to-individual/request/:requestId/accept/contract',
  SERVICE_TRANSFER_E_TO_I_ACCEPT_ATTACHMENTS = '/app/service-transfer/establishment-to-individual/request/:requestId/accept/attachments',
  SERVICE_TRANSFER_E_TO_I_ACCEPT_PAYMENT = '/app/service-transfer/establishment-to-individual/request/:requestId/accept/payment',

  SERVICE_TRANSFER_I_TO_E_REQUEST = '/app/service-transfer/individual-to-establishment/request/:requestId',

  SERVICE_TRANSFER_I_TO_I_REQUEST = '/app/service-transfer/individual-to-individual/request/:requestId',
  SERVICE_TRANSFER_I_TO_I_REQUEST_APPROVAL = '/app/service-transfer/individual-to-individual/request/approval',
  SERVICE_TRANSFER_I_TO_I_REQUEST_LABORERS = '/app/service-transfer/individual-to-individual/request/laborers',
  SERVICE_TRANSFER_I_TO_I_REQUEST_FORM = '/app/service-transfer/individual-to-individual/request/form',
  SERVICE_TRANSFER_I_TO_I_APPROVAL = '/app/service-transfer/individual-to-individual/request/:requestId/approval',
  SERVICE_TRANSFER_I_TO_I_TRANSFER_CONTRACT = '/app/service-transfer/individual-to-individual/request/:requestId/transfer-contract',
  SERVICE_TRANSFER_I_TO_I_EMPLOYMENT_CONTRACT = '/app/service-transfer/individual-to-individual/request/:requestId/employment-contract',
  SERVICE_TRANSFER_I_TO_I_ATTACHMENTS = '/app/service-transfer/individual-to-individual/request/:requestId/attachments',
  SERVICE_TRANSFER_I_TO_I_PAYMENT = '/app/service-transfer/individual-to-individual/request/:requestId/payment',

  FARMER_TRANSFER_REQUEST_FORM = '/app/service-transfer/farmer-request/form',
  FARMER_TRANSFER_REQUEST_SUMMARY = '/app/service-transfer/farmer-request/request-summary',
  FARMER_TRANSFER_REQUEST_DISCLAIMER = '/app/service-transfer/farmer-request/disclaimer',
  FARMER_TRANSFER_REQUEST_PAYMENT = '/app/service-transfer/farmer-request/:requestId/payment',
  // MaarofaRequest
  MAAROFA_WORKER_TYPE = '/app/contracts/maarofa/worker-type',
  MAAROFA_APPROVAL = '/app/contracts/maarofa/approval',
  MAAROFA_SELECT_VISA = '/app/contracts/maarofa/select-visa',
  MAAROFA_WORKER_INFO = '/app/contracts/maarofa/worker-info',
  MAAROFA_NATIONAL_ADDRESS = '/app/contracts/maarofa/national-address',
  MAAROFA_OFFICE_TYPE = '/app/contracts/maarofa/office-type',
  MAAROFA_OFFICE_SELECT = '/app/contracts/maarofa/office-select',

  // Contract authentication
  CONTRACT_AUTH_INSTRUCTION = '/app/contracts/request-authentication/instruction',
  CONTRACT_AUTH_APPROVAL = '/app/contracts/request-authentication/approval',
  CONTRACT_AUTH_LABORERS_LIST = '/app/contracts/request-authentication/laborers-list',
  CONTRACT_AUTH_REQUEST_DETAILS = '/app/contracts/request-authentication/contract-details',
  CONTRACT_AUTH_LABORER_DETAILS = '/app/contracts/request-authentication/laborer-details',
  CONTRACT_AUTH_CONTRACT_PREVIEW = '/app/contracts/request-authentication/contract-preview',

  CONTRACT_AUTH_REVIEW_CONTRACT = '/app/contracts/authentication/:requestId/review-authentication-contract',
  CONTRACT_AUTH_CANCELLATION = '/app/contracts/authentication/:requestId/cancel-authentication-contract',
  CONTRACT_AUTH_EDIT_CONTRACT = '/app/contracts/authentication/:requestId/edit-authentication-contract',
  CONTRACT_AUTH_REVIEW_APPENDIX = '/app/contracts/authentication/:requestId/review-authentication-appendix',
  CONTRACT_AUTH_EDIT_MOBILE = '/app/contracts/authentication/:requestId/edit-laborer-mobile',

  // Complaints
  COMPLAINTS = '/app/complaints',
  COMPLAINT_DETAILS = '/app/complaints/:ticketKey',

  // Profile
  PROFILE = '/app/profile',
  PROFILE_DETAILS = '/app/profile/details',
  PROFILE_REFUND = '/app/profile/refund',
  PROFILE_REFUND_SADAD = '/app/profile/refund/sadad/:billId',
  PROFILE_REFUND_PAY_FORT = '/app/profile/refund/payfort/:billId',
  PROFILE_SPONSORED_LIST = '/app/profile/sponsored-list',
  RUNAWAYS = '/app/profile/runaways',
  PROFILE_CHANGE_PASSWORD = '/app/profile/change-password',

  // Visas
  VISAS = '/app/evisa',
  VISA_DETAILS = '/app/evisa/:requestId/details',
  FARMER_VISA_DETAILS = '/app/evisa/farmer/:requestId/details',
  VISA_PAYMENT = '/app/evisa/:requestId/payment',
  FARMER_VISA_PAYMENT = '/app/evisa/farmer/:requestId/payment',
  FARMER_VISA_REFUND_SADAD = '/app/evisa/farmer/:requestId/refund/sadad/:billId',
  FARMER_VISA_REFUND_PAYFORT = '/app/evisa/farmer/:requestId/refund/payfort/:billId',
  VISA_REFUND_SADAD = '/app/evisa/:requestId/refund/sadad/:billId',
  VISA_REFUND_PAYFORT = '/app/evisa/:requestId/refund/payfort/:billId',

  // Regular visa request
  REGULAR_VISA_INSTRUCTION = '/app/evisa/regular/instruction',
  REGULAR_VISA_DISCLAIMER = '/app/evisa/regular/disclaimer',
  REGULAR_VISA_ATTACHMENTS = '/app/evisa/regular/attachments',
  REGULAR_VISA_CONFIRMATION = '/app/evisa/regular/confirmation',

  // Farmer visa request
  FARMER_VISA_DESCRIPTION = '/app/evisa/farmer/description',
  FARMER_VISA_DISCLAIMER = '/app/evisa/farmer/disclaimer',
  FARMER_VISA_CONFIRMATION = '/app/evisa/farmer/confirmation',
  FARMER_VISA_ENDORSEMENT_SELECT = '/app/evisa/farmer/endorsement-select',

  // Farmer visa Agricultural file creation
  FARMER_VISA_ESTABLISHMENT_REQUEST = '/app/evisa/farmer/establishment/request',
  FARMER_VISA_ESTABLISHMENT_CONFIRMATION = '/app/evisa/farmer/establishment/confirmation',

  // Disability visa request
  DISABILITY_VISA_DISCLAIMER = '/app/evisa/disability/disclaimer',
  DISABILITY_VISA_ATTACHMENTS = '/app/evisa/disability/attachments',
  DISABILITY_VISA_CONFIRMATION = '/app/evisa/disability/confirmation',

  // Alternative visa request
  ALTERNATIVE_VISA_DISCLAIMER = '/app/evisa/replacement/disclaimer',
  ALTERNATIVE_VISA_REPLACEMENT = '/app/evisa/replacement/choose-replacement-visa',
  ALTERNATIVE_VISA_CONFIRMATION = '/app/evisa/replacement/confirmation',

  SUPPORT_TICKETS = '/app/support-tickets',
  SUPPORT_TICKET_DETAILS = '/app/support-tickets/details/:ticketId',
  SUPPORT_TICKETS_CREATE = '/app/support-tickets/create',
  SUPPORT_TICKETS_SELECT_CONTRACT = '/app/support-tickets/select-contract',
  SUPPORT_TICKETS_SELECT_VISA = '/app/support-tickets/select-visa',
  SUPPORT_TICKETS_SELECT_TRANSFER = '/app/support-tickets/select-transfer',

  COMMON_TERMS = '/terms/common',

  FAQ_REG_TERMS = '/terms/faq_reg',

  VISA_INQUIRY = '/visa-inquiry',
  COMPLAINT_INQUIRY = '/complaint-inquiry',

  WAGE_PROTECTION = '/app/labors',
  FINANCIAL_EXEMPTION = '/app/laborer/financial-exemption/:laborerId',
  WAGE_PROTECTION_LABOR_DETAILS = '/app/laborer/:laborerId',
  WAGE_PROTECTION_TRANSACTION_DETAILS = '/app/laborer/:laborerId/transactions/:transactionId',

  LABORER_INTERVIEW_DETAILS = '/app/interviews/:requestId',
  LABORER_INTERVIEW_REQUEST = '/app/interview-request',
  LABORER_INTERVIEW_REQUEST_DETAILS = '/app/interview-request/service-details',
  LABORER_INTERVIEW_REQUEST_PREFERENCES = '/app/interview-request/laborer-information',
  LABORER_INTERVIEW_REQUEST_OFFICES = '/app/interview-request/select-office',
  LABORER_INTERVIEW_REQUEST_PAYMENT = '/app/interview-request/payment',

  DIRECT_TRANSFER_REQUESTS = '/app/direct-transfer',
  DIRECT_TRANSFER_DETAILS = '/app/direct-transfer/:id',
  DIRECT_TRANSFER_OFFER = '/app/direct-transfer/offer/:id',
}

export const routeWithParams = (route: Routes | string, params: { [key: string]: string | number }): string =>
  Object.keys(params).reduce((path, paramName) => path.replace(`:${paramName}`, params[paramName] as string), route)
