import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'
import type { GetOfficeInfoResponse } from 'api/prosAPI'

export const convertResponseToState = (resp: GetOfficeInfoResponse) => ({
  id: resp.id,
  name: resp.name,
  vatNumber: resp.vat_number,
  address: resp.address.address,
  logoUrl: resp.address.logo_url,
  cityId: resp.address.city_id,
  eInvoicing: resp.e_invoicing,
  email: resp.address.email,
  mobileNumber: resp.address.mobile_number,
  phoneNumber: resp.address.phone_number,
  location: resp.address.location,
  rating: resp.rating,
})

type State = {
  pro: {
    id: number
    vatNumber: number
    name: string
    logoUrl: string
    cityId: number
    address: string
    eInvoicing?: string
    email: string
    mobileNumber: string
    phoneNumber: string
    location: string
    rating: string
  } | null
} & Status

const initialState = {
  pro: null,
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_OFFICE_INFO_ASYNC,
    Actions.GET_OFFICE_INFO_SUCCESS,
    Actions.GET_OFFICE_INFO_FAIL,
    GetOfficeInfoResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_OFFICE_INFO_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        pro: convertResponseToState(payload),
        ...action.status,
      }
    }
    case Actions.GET_OFFICE_INFO_ASYNC:
    case Actions.GET_OFFICE_INFO_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
