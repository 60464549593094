import { select, takeLatest } from 'redux-saga/effects'

import Actions from 'redux/actions'
import {
  getInterviewsList as getInterviewsListAction,
  getInterview as getInterviewAction,
  getLaborerPros as getLaborerProsAction,
  uploadPassportCopy as uploadPassportCopyAction,
  createInterview as createInterviewAction,
  payForInterview as payForInterviewAction,
  getPdfReport as getPdfReportAction,
  getInterviewBill as getInterviewBillAction,
} from 'redux/actionCreators/interviewAPI'
import interviewAPI from 'api/interviewAPI'
import { RootState } from 'redux/rootReducer'

import { getSimpleRequestHandler } from '../utils'

const getInterviewsList = getSimpleRequestHandler<
  typeof interviewAPI.getInterviewsList,
  ReturnType<typeof getInterviewsListAction>
>({
  actionLoading: Actions.GET_LABORER_INTERVIEWS_LIST_ASYNC,
  actionSuccess: Actions.GET_LABORER_INTERVIEWS_LIST_SUCCESS,
  actionFail: Actions.GET_LABORER_INTERVIEWS_LIST_FAIL,
  callApiFn: interviewAPI.getInterviewsList,
})

const getInterview = getSimpleRequestHandler<typeof interviewAPI.getInterview, ReturnType<typeof getInterviewAction>>({
  actionLoading: Actions.GET_LABORER_INTERVIEW_ASYNC,
  actionSuccess: Actions.GET_LABORER_INTERVIEW_SUCCESS,
  actionFail: Actions.GET_LABORER_INTERVIEW_FAIL,
  callApiFn: interviewAPI.getInterview,
})

const getLaborerPros = getSimpleRequestHandler<
  typeof interviewAPI.getLaborerPros,
  ReturnType<typeof getLaborerProsAction>
>({
  actionLoading: Actions.GET_LABORER_INTERVIEW_PROS_ASYNC,
  actionSuccess: Actions.GET_LABORER_INTERVIEW_PROS_SUCCESS,
  actionFail: Actions.GET_LABORER_INTERVIEW_PROS_FAIL,
  callApiFn: interviewAPI.getLaborerPros,
})

const uploadPassportCopy = getSimpleRequestHandler<
  typeof interviewAPI.uploadPassportCopy,
  ReturnType<typeof uploadPassportCopyAction>
>({
  actionLoading: Actions.UPLOAD_PASSPORT_COPY_ASYNC,
  actionSuccess: Actions.UPLOAD_PASSPORT_COPY_SUCCESS,
  actionFail: Actions.UPLOAD_PASSPORT_COPY_FAIL,
  callApiFn: interviewAPI.uploadPassportCopy,
})

const createInterview = getSimpleRequestHandler<
  typeof interviewAPI.createInterview,
  ReturnType<typeof createInterviewAction>
>({
  actionLoading: Actions.CREATE_LABORER_INTERVIEW_ASYNC,
  actionSuccess: Actions.CREATE_LABORER_INTERVIEW_SUCCESS,
  actionFail: Actions.CREATE_LABORER_INTERVIEW_FAIL,
  callApiFn: interviewAPI.createInterview,
})

function* payForInterviewSaga(action: ReturnType<typeof payForInterviewAction>) {
  const payForInterview = getSimpleRequestHandler<
    typeof interviewAPI.payForInterview,
    ReturnType<typeof payForInterviewAction>
  >({
    actionLoading: Actions.PAY_FOR_INTERVIEW_ASYNC,
    actionSuccess: Actions.PAY_FOR_INTERVIEW_SUCCESS,
    actionFail: Actions.PAY_FOR_INTERVIEW_FAIL,
    callApiFn: interviewAPI.payForInterview,
  })
  yield payForInterview(action)
  const payForInterviewStatus: RootState['payForInterviewStatus'] = yield select(
    (state: RootState) => state.payForInterviewStatus,
  )
  const formSubmitRedirect = action.payload.params.formSubmitRedirect
  if (payForInterviewStatus.success && formSubmitRedirect) formSubmitRedirect()
}

const getPdfReport = getSimpleRequestHandler<typeof interviewAPI.getPdfReport, ReturnType<typeof getPdfReportAction>>({
  actionLoading: Actions.GET_INTERVIEW_PDF_REPORT_ASYNC,
  actionSuccess: Actions.GET_INTERVIEW_PDF_REPORT_SUCCESS,
  actionFail: Actions.GET_INTERVIEW_PDF_REPORT_FAIL,
  callApiFn: interviewAPI.getPdfReport,
})

const getInterviewBill = getSimpleRequestHandler<
  typeof interviewAPI.getInterviewBill,
  ReturnType<typeof getInterviewBillAction>
>({
  actionLoading: Actions.GET_INTERVIEW_BILL_ASYNC,
  actionSuccess: Actions.GET_INTERVIEW_BILL_SUCCESS,
  actionFail: Actions.GET_INTERVIEW_BILL_FAIL,
  callApiFn: interviewAPI.getInterviewBill,
})

export default [
  takeLatest(Actions.GET_LABORER_INTERVIEWS_LIST, getInterviewsList),
  takeLatest(Actions.GET_LABORER_INTERVIEW, getInterview),
  takeLatest(Actions.GET_LABORER_INTERVIEW_PROS, getLaborerPros),
  takeLatest(Actions.UPLOAD_PASSPORT_COPY, uploadPassportCopy),
  takeLatest(Actions.CREATE_LABORER_INTERVIEW, createInterview),
  takeLatest(Actions.PAY_FOR_INTERVIEW, payForInterviewSaga),
  takeLatest(Actions.GET_INTERVIEW_PDF_REPORT, getPdfReport),
  takeLatest(Actions.GET_INTERVIEW_BILL, getInterviewBill),
]
