import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'
import { GetPdfReportResponse } from 'api/interviewAPI'

type State = {} & Status

const initialState = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_INTERVIEW_BILL_ASYNC,
    Actions.GET_INTERVIEW_BILL_SUCCESS,
    Actions.GET_INTERVIEW_BILL_FAIL,
    GetPdfReportResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_INTERVIEW_BILL_ASYNC:
    case Actions.GET_INTERVIEW_BILL_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_INTERVIEW_BILL_SUCCESS: {
      return {
        ...state,
        ...action.status,
      }
    }

    default:
      return state
  }
}
