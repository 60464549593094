import { AxiosRequestConfig } from 'axios'

import { NodeSelectOption } from 'ui-components/inputs/NodeSelect/NodeSelect'
import { ENABLE_FIX_STARS_RATING, IS_DEV } from 'common/envConstants'

import { ReligionTypes, ServiceTypes } from '../common/types/commonTypes'
import { MARKETPLACE_OFFERS_CARDS_PER_PAGE, MUSANED_SECURITY_HEADER_VALUE } from '../common/constants'
import { OfferFromBE, PublicOfferFromBE } from '../common/types/transformedRespTypes'

import { callAPI, prosAPI, prosAPIWithCredentials, prosLeasingAPIWithCredentials } from './axios'

export type GetMinimalPricesParams = {
  occupation: number
  perNationality?: boolean
  serviceType: ServiceTypes
}

export type GetMinimalPricesResponse = {
  data: Array<{
    min_price: number
    nationality_id: number
  }>
}

const getMinimalPrices = (
  { occupation, perNationality = true, serviceType }: GetMinimalPricesParams,
  config?: AxiosRequestConfig,
) =>
  prosAPI.get<GetMinimalPricesResponse>('/api/services/least_prices', {
    params: { occupation, perNationality, service_type: serviceType },
    ...config,
  })

export type GetPriceRangeResponse = {
  min: number
  max: number
}

const getPriceRange = (params = {}, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetPriceRangeResponse>('/api/services/price_range', { ...params, ...config })

export type GetRecruitmentDurationRangeResponse = {
  min_duration: number
  max_duration: number
}

const getRecruitmentDurationRange = (params = {}, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetRecruitmentDurationRangeResponse>('/api/services/duration_range', {
    ...params,
    ...config,
  })

export type GetMarketOffersParams = {
  occupation?: number
  typeOfRecruitment: ServiceTypes
  nationality?: number
  priceSort: string
  ratingSort: string
  officeNameSearch: string
  costRange: {
    min?: number
    max?: number
  }
  durationRange: {
    min?: number
    max?: number
  }
  arrivalCityId?: number
  religion?: number
  starsRating: number
  acceptanceRateRange: {
    min?: number
    max?: number
  }
  page: number
  clearPagesCount: boolean
  gender?: number
  isUserInfo?: boolean
  ageRange?: number
  workExperience?: number
  extraPreferences?: NodeSelectOption[]
  premium?: number
}

export type GetMarketOffersResponse = {
  data: Array<OfferFromBE>
  meta: {
    current_page: number
    last_page: number
    total: number
  }
}

const getMarketOffers = (params: GetMarketOffersParams, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetMarketOffersResponse>('/api/services/list', {
    params: {
      'sort[price]': params.priceSort,
      'sort[rate]': params.ratingSort,
      name: params.officeNameSearch,
      price_min: params.costRange.min,
      price_max: params.costRange.max,
      min_recruitment_period: params.durationRange.min,
      max_recruitment_period: params.durationRange.max,
      service_type: params.typeOfRecruitment,
      arrival_city_id: params.arrivalCityId,
      occupation: params.occupation,
      nationality: params.nationality,
      religion_id: params.religion,
      min_rate: ENABLE_FIX_STARS_RATING ? params.starsRating : params.starsRating * 2,
      minAcceptance: params.acceptanceRateRange.min,
      maxAcceptance: params.acceptanceRateRange.max,
      page: params.page + 1,
      per_page: MARKETPLACE_OFFERS_CARDS_PER_PAGE,
      includeTransportableServices: true,
      premium: params.premium || '',
    },
    ...config,
  })

export type GetAllMarketOffersResponse = {
  data: Array<PublicOfferFromBE>
  current_page: number
  last_page: number
  total: number
}

const getAllMarketOffers = (params: GetMarketOffersParams, config?: AxiosRequestConfig) => {
  const headers = {
    ...config?.headers,
  }

  if (IS_DEV) {
    headers['x-musaned-security-header'] = MUSANED_SECURITY_HEADER_VALUE
  }

  return prosAPI.get<GetAllMarketOffersResponse>('/api/offices/marketplace', {
    params: {
      'sort[price]': params.priceSort,
      rating_order: params.ratingSort,
      name: params.officeNameSearch,
      price_min: params.costRange.min,
      price_max: params.costRange.max,
      min_recruitment_period: params.durationRange.min,
      max_recruitment_period: params.durationRange.max,
      service_type: params.typeOfRecruitment,
      city_id: params.arrivalCityId,
      occupation: params.occupation,
      nationality: params.nationality,
      religion_id: params.religion,
      min_rate: ENABLE_FIX_STARS_RATING ? params.starsRating : params.starsRating * 2,
      minAcceptance: params.acceptanceRateRange.min,
      maxAcceptance: params.acceptanceRateRange.max,
      page: params.page + 1,
      per_page: MARKETPLACE_OFFERS_CARDS_PER_PAGE,
      includeTransportableServices: true,
    },
    headers,
    ...config,
  })
}

export type GetMaarofaOfficesParams = {
  arrivalCityId?: number
  name?: string
  occupation?: number
  nationality?: number
}

export type MaarofaOfficeFromBE = {
  name: string
  rating: string
  address: {
    city_id: number
    logo_url: string
    location: string
    mobile_number: string
    phone_number: string
    address: string
    email: string
  }
  services: Array<{
    id: number
    pro_id: number
    religion_id: ReligionTypes
    price: number
    recruitment_period: number
  }>
}

export type GetMaarofaOfficesResponse = {
  offices: Array<MaarofaOfficeFromBE>
}

const getMaarofaOffices = (params: GetMaarofaOfficesParams, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetMaarofaOfficesResponse>('/api/offices', {
    params: {
      is_direct_contract: true,
      includeTransportableServices: true,
      service_type: ServiceTypes.DIRECT_CONTRACT,
      arrival_city_id: params.arrivalCityId,
      name: params.name,
      occupation: params.occupation,
      nationality: params.nationality,
    },
    ...config,
  })

export type GetOfficeInfoParams = {
  proOfficeId: string | number
}

export type GetOfficeInfoResponse = {
  id: number
  name: string
  vat_number: number
  address: {
    address: string
    logo_url: string
    city_id: number
    email: string
    mobile_number: string
    phone_number: string
    location: string
  }
  e_invoicing?: string
  rating: string
}

const getOfficeInfo = ({ proOfficeId }: GetOfficeInfoParams, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetOfficeInfoResponse>(`/api/takamol/offices/${proOfficeId}`, config)

export type GetMaarofaServiceTemplateResponse = {
  data: Array<{
    nationality_id: number
    occupation_id: number
    price: number
    recruitment_period: number
  }>
}

const getMaarofaServiceTemplate = (params = {}, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetMaarofaServiceTemplateResponse>('api/service_templates', { params, ...config })

export type GetProServiceDetailsParams = {
  trashed?: boolean
  expand?: Array<'serviceTemplate'>
  id: number | string
}

export type GetProServiceDetailsResponse = {
  service_template: {
    bank_fees: number
  } | null
}

const getProServiceDetails = (
  { id, trashed = true, expand = ['serviceTemplate'] }: GetProServiceDetailsParams,
  config?: AxiosRequestConfig,
) =>
  prosAPIWithCredentials.get<GetProServiceDetailsResponse>(`/api/services/${id}`, {
    params: {
      trashed,
      expand,
    },
    ...config,
  })

export type GetProServiceAddonsParams = {
  serviceId: number | string
}

export type ServiceAddonsFromBE = {
  id: number
  name: string
  category_max_count: number
  category_min_count: number
  description: string
  taxable: number
  pivot: {
    cost: number
    note: string
    pro_service_id: number
    recruited_addon_category_id: number
  }
}

export type GetProServiceAddonsResponse = {
  pro_id: string
  service_addons: Array<ServiceAddonsFromBE> | null
}

const getProServiceAddons = ({ serviceId }: GetProServiceAddonsParams, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetProServiceAddonsResponse>(`/api/services/${serviceId}`, {
    params: {
      expand: ['serviceAddons'],
    },
    ...config,
  })

export type GetProRecruitedServiceAddonsResponse = {
  data: Array<{
    id: number
    name: string
    category_max_count: number
    category_min_count: number
    description: string
    taxable: number
    is_offered: number
  }>
}

const getProRecruitedServiceAddons = (params = {}, config?: AxiosRequestConfig) =>
  prosAPIWithCredentials.get<GetProRecruitedServiceAddonsResponse>(
    '/api/services/recruited_addon_categories?is_offered=1',
    { ...params, ...config },
  )

export type GetVipProsParams = {
  gender?: string
  occupationCode?: number
  nationalityCode?: number
}

export type GetVipProsResponse = {
  data: {
    id: number
    occupation: string
    nationality: string
    type: string
    pro: {
      address?: string
      email: string
      external_pro_id: number
      iban: string
      iban_beneficiary: string
      id: number
      laborer_office_id: string
      logo_url: string
      mobile_number: string
      name: string
      sequence_number: string
    }
  }[]
}

const getVipPros = ({ gender, occupationCode, nationalityCode }: GetVipProsParams, config?: AxiosRequestConfig) =>
  prosLeasingAPIWithCredentials.get<GetVipProsResponse>('api/vip/preference', {
    params: {
      gender,
      occupation_code: occupationCode,
      nationality_code: nationalityCode,
    },
    ...config,
  })

export type SelectVipProsParams = {
  pros: {
    office_id: number
    sequence_number: string
    service_id: number
  }[]
  preferences: {
    age_range_id?: string
    religion?: string
    language?: string
    prev_exp?: string
    years_exp?: number
    skills?: string
    duration?: number
    criteria?: string
  } | null
}

const selectVipPros = (body: SelectVipProsParams, config: AxiosRequestConfig) =>
  prosLeasingAPIWithCredentials.post('api/vip/offer', body, config)

export default {
  getMaarofaServiceTemplate: callAPI<typeof getMaarofaServiceTemplate, GetMaarofaServiceTemplateResponse>(
    getMaarofaServiceTemplate,
  ),
  getProServiceDetails: callAPI<typeof getProServiceDetails, GetProServiceDetailsResponse>(getProServiceDetails),
  getMinimalPrices: callAPI<typeof getMinimalPrices, GetMinimalPricesResponse>(getMinimalPrices),
  getPriceRange: callAPI<typeof getPriceRange, GetPriceRangeResponse>(getPriceRange),
  getMaarofaOffices: callAPI<typeof getMaarofaOffices, GetMaarofaOfficesResponse>(getMaarofaOffices),
  getRecruitmentDurationRange: callAPI<typeof getRecruitmentDurationRange, GetRecruitmentDurationRangeResponse>(
    getRecruitmentDurationRange,
  ),
  getMarketOffers: callAPI<typeof getMarketOffers, GetMarketOffersResponse>(getMarketOffers),
  getAllMarketOffers: callAPI<typeof getAllMarketOffers, GetAllMarketOffersResponse>(getAllMarketOffers),
  getOfficeInfo: callAPI<typeof getOfficeInfo, GetOfficeInfoResponse>(getOfficeInfo),
  getProServiceAddons: callAPI<typeof getProServiceAddons, GetProServiceAddonsResponse>(getProServiceAddons),
  getProRecruitedServiceAddons: callAPI<typeof getProRecruitedServiceAddons, GetProRecruitedServiceAddonsResponse>(
    getProRecruitedServiceAddons,
  ),
  getVipPros: callAPI<typeof getVipPros, GetVipProsResponse>(getVipPros),
  selectVipPros: callAPI<typeof selectVipPros, {}>(selectVipPros),
}
